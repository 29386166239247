import React from "react"
import { FlexRow, FlexCol } from "components/FlexGrid"

import useMedia from "hooks/useMedia.js"
import { GetLocationsData } from "utils/locationsData"
import {
  FindUsModuleContainer,
  Title,
  LocationsContainer,
  MapModule,
  MapImg,
  MapText,
  InnerGrid,
} from "./style"

const FindUsModule = () => {
  const locationsData = GetLocationsData()
  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  return (
    <FindUsModuleContainer gap={[20, 30, 45, 60]}>
      <FlexRow>
        <FlexCol xs={12} sm={3}>
          <Title>Find us</Title>
        </FlexCol>
        <FlexCol xs={12} sm={9}>
          <LocationsContainer>
            <p>
              We are based in Auckland, yet we work with clients from all over
              the world. Whether you have a project you'd like to discuss, or
              you'd just like to find out more about how we work, please give us
              a call or send us an email.
            </p>

            <InnerGrid fullWidth gap={[0, 30, 45, 60]}>
              <FlexRow>
                {locationsData.map((location, index) => {
                  return (
                    <FlexCol key={index} xs={12}>
                      <MapModule first={index === 0}>
                        <MapImg fluid={location.img} />
                        <MapText>
                          <h4>{location.title}</h4>
                          <p
                            className="address"
                            dangerouslySetInnerHTML={{
                              __html: location.address,
                            }}
                          />
                          {location.people.map((person, index) => {
                            return (
                              <div className="person" key={index}>
                                <span className="name">{person.name}</span>
                                <div className="number">
                                  <span>P</span>
                                  {isMobile ? (
                                    <a href={`tel:${person.mobile}`}>
                                      {person.mobile}
                                    </a>
                                  ) : (
                                    <p>{person.mobile}</p>
                                  )}
                                </div>
                                <a
                                  className="email"
                                  href={`mailto:${person.email}`}
                                >
                                  Email {person.name.split(" ", 2)[0]}
                                </a>
                              </div>
                            )
                          })}
                        </MapText>
                      </MapModule>
                    </FlexCol>
                  )
                })}
              </FlexRow>
            </InnerGrid>
          </LocationsContainer>
        </FlexCol>
      </FlexRow>
    </FindUsModuleContainer>
  )
}

export default FindUsModule
