import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid } from "components/FlexGrid"
import AvenueImg from "components/AvenueImg"

export const FindUsModuleContainer = styled(FlexGrid)`
  padding-top: 50px;
  margin-top: 25px;
  padding-bottom: 30px;
  border-top: 1px solid rgba(158, 157, 168, 0.3);

  @media ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 90px;
    margin-top: 65px;
  }
  @media ${device.desktop} {
    padding-top: 120px;
    padding-bottom: 130px;
    margin-top: 120px;
  }
`

export const Title = styled.h2`
  font-size: 42px;
  color: ${props => props.theme.blue};
  @media ${device.tablet} {
    font-size: 47px;
  }

  @media ${device.desktop} {
    font-size: 56px;
  }
`

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */

  > p {
    font-size: 18px;
    color: #5a5a5a;
    /* margin-bottom: 40px; */
    font-weight: 300;
    /* width: 100%; */

    @media ${device.tablet} {
      margin-bottom: 30px;
    }
    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 55px;
    }
  }
`

export const MapModule = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 35px 0;
  border-bottom: ${props =>
    props.first && `1px solid rgba(158, 157, 168, 0.3)`};

  @media ${device.tablet} {
    border: none;
    width: 100%;
    margin: 0;

    padding: 0;
  }
`
export const MapImg = styled(AvenueImg)`
  margin-right: 10px;
  width: 140px;
  height: 140px;
  flex-shrink: 0;

  @media ${device.tablet} {
    width: 100%;
    max-width: 247px;
    height: auto;
    margin-bottom: 30px;
  }

  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`
export const MapText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 10px;
  color: #5a5a5a;
  align-items: flex-start;
  @media ${device.tablet} {
    font-size: 16px;
    padding-left: 30px;
  }
  @media ${device.desktop} {
    padding-left: 50px;
  }

  h4 {
    color: ${props => props.theme.blue};
    font-size: 16px;
    margin-bottom: 8px;

    @media ${device.tablet} {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  p.address {
    margin-bottom: 13px;
    font-weight: 300;
    color: #5a5a5a;
    font-size: 14px;

    @media ${device.tablet} {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }

  span.name {
    font-weight: 500;
    margin-bottom: 5px;
  }

  div.person {
    margin-bottom: 20px;
  }

  div.number {
    position: relative;

    a,
    p {
      font-weight: 300;
      margin-left: 9px;
      color: #5a5a5a;
      display: inline;

      :before {
        display: none;
      }
    }

    span {
      font-weight: 500;
    }
  }

  a.email {
    font-weight: 600;
  }

  a {
    position: relative;
    display: inline-block;
    :before {
      content: "";
      width: 100%;
      height: 2px;
      opacity: 0;
      transition: 70ms;
      background: ${props => props.theme.green};
      position: absolute;
      bottom: -1px;
      left: 0;
    }

    :hover {
      :before {
        opacity: 1;
      }
    }
  }
`

export const InnerGrid = styled(FlexGrid)`
  margin-left: 0;

  @media ${device.tablet} {
    max-width: 550px;
  }
`
